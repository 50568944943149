import {
    ArrowPathIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/react/20/solid'
import { BoltIcon, CalendarDaysIcon, ExclamationCircleIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Spinner, Icon, Box, HStack, Image, Stack, VStack, Input, Container } from '@chakra-ui/react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { TypeAnimation } from 'react-type-animation';
import { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Marquee from 'react-fast-marquee';
import { API } from 'aws-amplify';
import SignUpBox from '../components/SignUpBox';


const footerNavigation = {
    social: [
        {
            name: 'Twitter',
            href: 'https://twitter.com/mikegeecmu',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
            ),
        },
        {
            name: 'YouTube',
            href: 'https://youtube.com/@mike-gee',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}



const adviceList = [
    {
        'id': 'paul-graham',
        'name': 'Paul Graham',
        'title': 'Co-Founder of ViaWeb, Y Combinator',
        'messages': [
            "When it comes to hiring, founders should focus on addressing the fundamental problem of their product's appeal rather than overestimating the need for more employees. Hiring people is rarely the solution to boosting growth, especially if the product is only moderately appealing.",
            "Airbnb is a great example of a startup that waited to hire their first employee and focused on evolving their product, which ultimately led to their success.",
        ],
        'citation': "http://paulgraham.com/aord.html",
    },
    {
        'id': 'andrew-carnegie',
        'name': 'Andrew Carnegie',
        'title': 'Founder of Carnegie Steel',
        'messages': [
            "When it comes to hiring, I believe in being cautious and deliberate. It is important to ask yourself two questions: Do I have the necessary resources to support the new hire? And am I willing to take responsibility for their success or failure? If the answers to these questions are affirmative, then it may be wise to proceed with the hire.",
            "I have always believed in the power of investing in my own employees and helping them grow within the company. For example, when we needed to expand our iron business, we focused on training and promoting from within rather than hiring external candidates. This not only saved us money but also fostered loyalty and dedication among our employees.",
        ],
        'citation': "https://www.gutenberg.org/files/17976/17976-h/17976-h.htm",
    },
    {
        'id': 'david-sacks',
        'name': 'David Sacks',
        'title': 'Co-Founder of SaaSGrid',
        'messages': [
            "When it comes to hiring, it's important to find the right combination of skills and personalities for your founding team. This can include a mix of business, technical, product, and sales expertise. It's also helpful to partner with someone you have a history of working with, as creating a company together is like a marriage. In my experience, I partnered with Max Levchin and Peter Thiel at PayPal, and we were able to build a successful team based on our friendship and shared goals.",
            "Additionally, it's important to be plugged into a network to find the right talent. Silicon Valley has a dense network of skilled professionals, making it easier to find the expertise you need. For example, when we needed enterprise salespeople at Yammer, we were able to poach them from Salesforce because they had experience selling cloud products and knew how to address enterprise concerns.",
        ],
        'citation': 'https://www.youtube.com/watch?v=9KX920RJTp0&t=4s',
    }
]


const navigation = [
    { name: 'Chat', href: '/' },
    { name: 'Weekly Advice', href: '/advice' },
    { name: 'Great Moments in History', href: '/feed' },
];



export default function Advice() {

    const [email, setEmail] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    const [selectedPersona, setSelectedPersona] = useState('steve-jobs');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    function isLegitEmail(email) {
        const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return pattern.test(email);
    }


    async function submitEmail() {
        setLoadingEmail(true);
        setValidEmail(true);

        if (isLegitEmail(email)) {
            const out = await API.get('mentorchat', '/email', {
                response: true,
                queryStringParameters: {
                    kind: 'add',
                    email: email,
                },
            });
            if (out.status == 200) {
                setSubmitSuccess(true);
            }
        } else {
            setValidEmail(false);
            setLoadingEmail(false);
        }
        setLoadingEmail(false);
    }


    return (
        <div className="bg-gray-900">
            {/* Header */}
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-start justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <VStack
                            alignItems='start'
                        >
                            <a href='/dashboard'>
                                <img
                                    className="h-8 w-auto"
                                    src="/lalaland.png"
                                    alt="Lalaland"
                                />
                            </a>
                        </VStack>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <HStack spacing={6}>
                            <a href="/dashboard" className="text-sm font-semibold leading-6 text-white">
                                Log in
                            </a>
                            <a href='/dashboard'>
                                <button
                                    type="button"
                                    className="animate-pulse rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                >
                                    <HStack>
                                        <p>
                                            Start Chatting
                                        </p>
                                        <Icon as={FiArrowRight} />
                                    </HStack>
                                </button>
                            </a>
                        </HStack>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <img
                                    className="h-8 w-auto"
                                    src='/lalaland.png'
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <VStack spacing={6} alignItems='start'>
                                        <a
                                            href="/dashboard"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            Log in
                                        </a>
                                        <button
                                            type="button"
                                            className="animate-pulse rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                            onClick={() => window.open('/dashboard')}
                                        >
                                            <HStack>
                                                <p>
                                                    Start Chatting
                                                </p>
                                                <Icon as={FiArrowRight} />
                                            </HStack>
                                        </button>
                                    </VStack>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

            <main>
                <div className="pt-10 relative isolate overflow-hidden min-h-screen">
                    <svg
                        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
                    </svg>
                    <div
                        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-[95rem] px-6 pb-8 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-20">
                        <div className="mx-auto max-w-2xl flex-shrink-0 xl:mx-0 xl:max-w-xl xl:pt-8">
                            <img
                                className='hidden md:flex h-20 w-20 rounded-full border-4 border-white object-cover'
                                src={'/circle/steve-jobs-lisa.jpg'}
                            />
                            <h1 className="mt-8 md:mt-16 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                A different topic,
                            </h1>
                            <h1 className="mt-0 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                every week.
                            </h1>
                            <p className="hidden sm:flex mt-6 text-lg leading-8 text-gray-300">
                                Get the synthesized thoughts from the greatest minds in history. Straight to your inbox, every week.
                            </p>
                            <SignUpBox mt={8} />
                        </div>
                        <div className="hidden xl:flex mx-auto w-full flex ml-0 lg:ml-16">
                            <VStack
                                backgroundColor='white'
                                borderRadius='16px'
                                overflow='hidden'
                                width='100%'
                                p={7}
                                spacing={6}
                            >
                                <VStack
                                    alignItems='start'
                                    width='100%'
                                >
                                    <p className='font-semibold text-3xl'>
                                        Weekly Advice: On Hiring
                                    </p>
                                </VStack>

                                {adviceList.map((mentor, index) => (
                                    <HStack
                                        width='100%'
                                        alignItems='start'
                                        spacing={4}
                                    >
                                        <img
                                            src={`/img/${mentor.id}.png`}
                                            className='h-16 w-16 rounded-full'
                                        />
                                        <VStack
                                            alignItems='start'
                                            width='100%'
                                            spacing={0}
                                        >
                                            <p className='font-semibold text-lg'>
                                                {mentor.name}
                                            </p>
                                            <p className='text-base font-semibold'>
                                                {mentor.title}
                                            </p>
                                            <ul>
                                                {mentor.messages.map((message) => (
                                                    <li className='list-disc ml-4'>
                                                        {message}
                                                        <a href={mentor.citation} target='_blank' className='font-semibold text-indigo-600 hover:underline ml-1'>
                                                            [{index + 1}]
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </VStack>
                                    </HStack>
                                ))}
                            </VStack>
                        </div>
                    </div>
                    <div className="flex xl:hidden mx-auto max-w-4xl flex-shrink-0 xl:mx-0 xl:max-w-xl">
                        <VStack
                            backgroundColor='white'
                            borderRadius='16px'
                            overflow='hidden'
                            width='100%'
                            p={7}
                            spacing={9}
                        >
                            <VStack
                                alignItems='start'
                                width='100%'
                            >
                                <p className='font-semibold text-3xl'>
                                    Weekly Advice: On Hiring
                                </p>
                            </VStack>

                            {adviceList.map((mentor, index) => (
                                <VStack
                                    alignItems='start'
                                    width='100%'
                                >
                                    <img
                                        src={`/img/${mentor.id}.png`}
                                        className='h-16 w-16 rounded-full flex sm:hidden'
                                    />
                                    <HStack
                                        width='100%'
                                        alignItems='start'
                                        spacing={4}
                                    >
                                        <img
                                            src={`/img/${mentor.id}.png`}
                                            className='h-16 w-16 rounded-full hidden sm:flex'
                                        />
                                        <VStack
                                            alignItems='start'
                                            width='100%'
                                            spacing={0}
                                        >
                                            <p className='font-semibold text-lg'>
                                                {mentor.name}
                                            </p>
                                            <p className='text-base font-semibold'>
                                                {mentor.title}
                                            </p>
                                            <ul>
                                                {mentor.messages.map((message) => (
                                                    <li className='list-disc ml-4'>
                                                        {message}
                                                        <a href={mentor.citation} target='_blank' className='font-semibold text-indigo-600 hover:underline ml-1'>
                                                            [{index + 1}]
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            ))}
                        </VStack>
                    </div>
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                </div>

            </main >

            {/* Footer */}
            < footer aria-labelledby="footer-heading" className="relative" >
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-0 lg:px-8">
                    <div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                        <div className="flex space-x-6 md:order-2">
                            {footerNavigation.social.map((item) => (
                                <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400" target='_blank'>
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                </a>
                            ))}
                        </div>
                        <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                            &copy; 2023 Vetro Technologies, Inc. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer >
        </div >
    )
}
