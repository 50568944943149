import './App.css';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import 'flowbite';
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider, useTheme } from '@aws-amplify/ui-react';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import PrivateNavbar from './components/PrivateNavbar';
import Dashboard from './pages/Dashboard';
import Chat from './pages/Chat';
import Advice from './pages/Advice';
import GreatsFeed from './pages/TheGreats';
import Pricing from './pages/Pricing';

function App() {

  useEffect(() => {
    // ReactGA.initialize([
    // {
    // trackingId: "G-W40B7XF6DD",
    // },
    // ]);
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const { tokens } = useTheme();
  const theme = {
    name: 'Lalaland Auth Theme',
    tokens: {
      colors: {
        font: {
          _hover: {
            value: 'black',
          },
          interactive: {
            value: 'black',
          },
        },
        brand: {
          primary: {
            '10': '#ffffff',
            '70': '#ffffff',
            '80': '#ffffff',
            '90': '#ffffff',
            '100': '#ffffff',
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: 'black',
              },
            },
            _hover: {
              color: {
                value: 'black',
              },
            },
            _active: {
              color: {
                value: 'black',
              },
            },
          },
        },
      },
    },
  };


  return (
    <ChakraProvider>
      <ThemeProvider theme={theme} className='bg-white'>
        <BrowserRouter className='bg-white'>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/advice' element={<Advice />} />
            <Route exact path='/feed' element={<GreatsFeed />} />
            <Route exact path='/dashboard' element={<PrivateNavbar>
              <Dashboard />
            </PrivateNavbar>} />
            <Route exact path='/chat/:id' element={<PrivateNavbar>
              <Chat />
            </PrivateNavbar>} />
            <Route exact path='/credits' element={<PrivateNavbar>
              <Pricing />
            </PrivateNavbar>} />
          </Routes >
        </BrowserRouter>
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
