import {
    ArrowPathIcon,
    ArrowRightIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/react/20/solid'
import { BoltIcon, CalendarDaysIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Box, HStack, Icon, Skeleton, Stack, VStack, Wrap } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';


export default function MentorList({ title, mentors }) {
    console.log('mentors');
    console.log(mentors);

    return (
        <div className="mx-auto max-w-7xl px-6 lg:px-8 flex-start w-full">
            <VStack
                alignItems='start'
                spacing={6}
            >
                <p className='text-3xl font-semibold text-white'>
                    {title}
                </p>
                <div className='hidden sm:block'>
                    <Wrap
                        alignItems='start'
                        width='100%'
                        spacing={4}
                    >
                        {!mentors &&
                            <HStack
                                spacing={4}
                            >
                                <Skeleton
                                    height='64'
                                    width='64'
                                    borderRadius='2xl'
                                />
                                <Skeleton
                                    height='64'
                                    width='64'
                                    borderRadius='2xl'
                                />
                            </HStack>
                        }
                        {mentors && mentors.map((mentor) => {
                            if (mentor) {
                                return (
                                    <a href={`/chat/${mentor.id}`} class="relative w-64 h-64 overflow-hidden cursor-pointer rounded-2xl">
                                        <img src={`/img/${mentor.id}.png`} alt="Description" class="grayscale hover:grayscale-0 w-full h-full object-cover transform transition-transform duration-150 hover:scale-105 cursor-pointer" />
                                        <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent pointer-events-none"></div>
                                        <div class="absolute bottom-0 left-0 right-0 p-4 pointer-events-none">
                                            <VStack
                                                alignItems='start'
                                                spacing={0}
                                            >
                                                <p class="text-xl font-semibold text-white">
                                                    {mentor.name}
                                                </p>
                                                <p class="font-base text-white">
                                                    {mentor.title}
                                                </p>
                                            </VStack>
                                        </div>
                                    </a>
                                );
                            }
                        })}
                    </Wrap>
                </div>
                <div className='block sm:hidden w-full'>
                    <VStack
                        alignItems='start'
                        width='100%'
                        spacing={4}
                    >
                        {!mentors &&
                            <VStack
                                spacing={4}
                                width='100%'
                            >
                                <Skeleton
                                    height='48'
                                    borderRadius='2xl'
                                    width='100%'
                                />
                                <Skeleton
                                    height='48'
                                    borderRadius='2xl'
                                    width='100%'
                                />
                                <Skeleton
                                    height='48'
                                    width='100%'
                                    borderRadius='2xl'
                                />
                            </VStack>
                        }
                        {mentors && mentors.map((mentor) => {
                            console.log('mentor');
                            console.log(mentor);
                            if (mentor) {
                                return (
                                    <a href={`/chat/${mentor.id}`} class="relative w-full overflow-hidden cursor-pointer rounded-2xl">
                                        <HStack
                                            alignItems='start'
                                            width='100%'
                                            spacing={5}
                                            backgroundColor='white'
                                            p={4}
                                        >
                                            <img src={`/img/${mentor.id}.png`} alt="Description" class="h-24 w-24 rounded-full object-top grayscale hover:grayscale-0 object-cover transform transition-transform duration-150 hover:scale-105 cursor-pointer" />
                                            <VStack
                                                alignItems='start'
                                                width='100%'
                                                mt={2}
                                                h={24}
                                            >
                                                <VStack
                                                    alignItems='start'
                                                    spacing={0}
                                                    width='100%'
                                                    justifyContent='space-between'
                                                    height='100%'
                                                >
                                                    <VStack
                                                        alignItems='start'
                                                        spacing={0}
                                                        width='100%'
                                                    >
                                                        <p class="text-lg font-semibold text-gray-900">
                                                            {mentor.name}
                                                        </p>
                                                        <p class="text-sm text-gray-900">
                                                            {mentor.title}
                                                        </p>
                                                    </VStack>
                                                    <HStack
                                                        width='100%'
                                                        justifyContent='end'
                                                    >
                                                        <ArrowRightIcon className='text-gray-500 h-4 w-5' />
                                                    </HStack>
                                                </VStack>
                                            </VStack>
                                        </HStack>
                                    </a>
                                );
                            }
                        })}

                    </VStack>
                </div>

            </VStack>
        </div>
    )
}
