import {
    ArrowPathIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/react/20/solid'
import { BoltIcon, CalendarDaysIcon, DocumentTextIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Tooltip, Textarea, Spinner, Box, Button, Container, HStack, Icon, Input, InputGroup, InputRightElement, Skeleton, SkeletonCircle, Stack, VStack, Wrap } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { BiSolidSend } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';

const footerNavigation = {
    solutions: [
        { name: 'Hosting', href: '#' },
        { name: 'Data Services', href: '#' },
        { name: 'Uptime Monitoring', href: '#' },
        { name: 'Enterprise Services', href: '#' },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Reference', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Claim', href: '#' },
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ],
    social: [
        {
            name: 'Facebook',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'Instagram',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'Twitter',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
            ),
        },
        {
            name: 'GitHub',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'YouTube',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}

const isValidUrl = (url) => { try { new URL(url); return true; } catch (_) { return false; } };

export default function Chat() {

    const [mentor, setMentor] = useState(null);
    const [messages, setMessages] = useState(null);
    const [error, setError] = useState(null);
    const [currMessage, setCurrMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);
    const inputRef = useRef(null);

    const { id } = useParams();


    useEffect(() => {
        getMentorDetails();
        inputRef.current.focus();
        getMessages();
    }, []);


    async function getMentorDetails() {
        const out = await API.get('mentorchat', '/mentors', {
            response: true,
            queryStringParameters: {
                kind: 'get',
                mentor_id: id,
            },
        });
        console.log(out);
        setMentor(out.data.mentor);
        if (out.data.mentor && out.data.mentor.name) {
            document.title = `${out.data.mentor ? out.data.mentor.name : 'Mentor'} | Timeless`;
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            sendMessage();
        }
    };

    async function appendUserMessageToMessages(message) {
        const newMessages = messages;
        newMessages.unshift({
            role: 'user',
            text_json: message,
        });
        setMessages(newMessages);
    }

    async function sendSelectedMessage(message) {
        sendMessage(message);
    }

    async function sendMessage(insertMessage = null) {
        var considerMessage = insertMessage || currMessage;
        setSendingMessage(true);
        setError(null);
        try {
            if (considerMessage.length > 0) {
                await appendUserMessageToMessages(considerMessage);
                const out = await API.get('mentorchat', '/mentors', {
                    response: true,
                    queryStringParameters: {
                        kind: 'send',
                        mentor_id: id,
                        chatbot_id: mentor.chatbot_id,
                        message: considerMessage,
                        mentor_name: mentor.name,
                        mentor_title: mentor.title,
                    },
                });
                console.log(out);
                if (!out.data.success) {
                    setSendingMessage(false);
                    setError(out.data.error || "Failed to send message.");
                }
                getMessages();
            } else {
                setError("Please send a valid message.");
                setSendingMessage(false);
            }
        } catch (e) {
            setSendingMessage(false);
            setError("Failed to send message.");
        }
    }

    async function getMessages() {
        const out = await API.get('mentorchat', '/mentors', {
            response: true,
            queryStringParameters: {
                kind: 'get_messages',
                mentor_id: id,
            },
        });
        console.log('get messages');
        console.log(out);
        if (out.data && out.data.messages) {
            const newMessages = out.data.messages.sort((a, b) => {
                return new Date(a.sent) - new Date(b.sent)
            });

            setMessages(newMessages);

            if (newMessages && (newMessages.length == 0) || (newMessages.length > 0 && newMessages[0].role != 'user')) {
                setCurrMessage('');
                setSendingMessage(false);
            } else {
                setTimeout(() => {
                    getMessages();
                }, 3000);
            }
        }
    }


    const suggestedQuestions = {
        'benjamin-franklin': [
            'What should I consider when picking my profession? Give examples of how you chose yours.',
            'Discuss what you mean by private and public benefit. Give examples of how you applied this.',
        ],
        'steve-jobs': [
            "I'm building an AI consumer hardware device. What should I think about? Refer to your time at NeXT.",
        ],
        'elon-musk': [
            "Why is it important that humanity become a multi-planetary species?"
        ],
        'david-sacks': [
            "What are your views on the relationship between the US and China?",
            "What attracts you to SaaS?",
        ],
        'andrew-carnegie': [
            "Who were those most kind to you in your life? How did they help you?",
            "What was your relationship with Henry Clay Frick like?",
        ],
        'john-f-kennedy': [
            "What was your relationship with your family like?",
            "I'm running for President. What lessons would you pass on to anybody interested on running?"
        ],
        'paul-graham': [
            "I'm in college but interested in starting a company. What should I do?",
            "I've gotten my first 100 customers but struggling to get the next 1000. What should I consider?"
        ],
    }


    return (
        <div className="bg-gray-900">
            <main>
                {/* Hero section */}
                <div className="relative isolate overflow-hidden">
                    <svg
                        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
                    </svg>
                    <div
                        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                            }}
                        />
                    </div>

                    <div className="py-6 sm:py-6">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <VStack
                                alignItems='start'
                                spacing={6}
                            >
                                {!mentor &&
                                    <HStack
                                        alignItems='start'
                                        width='100%'
                                        spacing={{ base: 4, md: 6 }}
                                    >

                                        <SkeletonCircle
                                            size={{ base: '12', md: '24' }}
                                        />

                                        <VStack
                                            alignItems='start'
                                            spacing={{ base: 2, md: 4 }}
                                        >
                                            <Skeleton
                                                height={{ base: '20px', md: '35px' }}
                                                width='150px'
                                            />
                                            <Skeleton
                                                height={{ base: '20px', md: '30px' }}
                                                width='280px'
                                            />
                                        </VStack>
                                    </HStack>
                                }
                                {mentor &&
                                    <HStack
                                        alignItems='start'
                                        width='100%'
                                        spacing={{ base: 4, md: 6 }}
                                    >
                                        <div class="relative w-16 h-16 md:w-24 md:h-24 overflow-hidden cursor-pointer rounded-full">
                                            <img src={`/img/${id}.png`} alt="Description" class="w-full h-full object-cover transform transition-transform duration-150 hover:scale-105 cursor-deafult" />
                                            <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent pointer-events-none"></div>
                                        </div>

                                        <VStack
                                            alignItems='start'
                                            spacing={0}
                                        >
                                            <Wrap
                                                spacingX={4}
                                                pb={2}
                                            >
                                                <p class="text-xl md:text-3xl font-semibold text-white">
                                                    {mentor.name}
                                                </p>
                                                <Tooltip label="I am an AI with access to information about this person. I do my best to talk in first-person, but I am not a real person. Views expressed do not reflect the views of the real person." position="bottom">
                                                    <button
                                                        type="button"
                                                        className="animate-pulse rounded-md bg-gray-300 px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-default"
                                                    >
                                                        AI Clone
                                                    </button>
                                                </Tooltip>
                                            </Wrap>
                                            <p class="text-lg md:text-2xl text-white">
                                                {mentor.title}
                                            </p>
                                        </VStack>
                                    </HStack>
                                }
                                {mentor &&
                                    <p className='text-xs sm:text-sm text-white'>
                                        This is an AI assitant designed to imitate a {mentor.name}. It is not a real person. Views expressed do not reflect the views of the real person. The AI assistant is subject to bias, error, and hallucination.
                                    </p>
                                }
                            </VStack>
                        </div>
                    </div>

                    <div className='max-w-full'>
                        <div className="mx-auto h-screen max-w-7xl px-0 lg:px-8">
                            <VStack
                                alignItems='start'
                                spacing={6}
                                backgroundColor='white'
                                borderRadius={{ base: '0px', sm: '16px' }}
                                borderTopRadius='16px'
                                width='100%'
                            >
                                <Container maxW='container.lg'>
                                    <VStack
                                        alignItems='start'
                                        spacing={6}
                                        overflow='hidden'
                                        width='100%'
                                        p={{ base: 0, md: 6 }}
                                        py={{ base: 4, md: 6 }}
                                        minH='full'
                                        justifyContent='space-between'
                                        css={{
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                width: '6px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                background: 'gray',
                                                borderRadius: '24px',
                                            },
                                        }}
                                    >
                                        <VStack
                                            alignItems='end'
                                            width='100%'
                                            height='60vh'
                                            flexDirection='column-reverse'
                                            spacing={6}
                                            overflowY='scroll'
                                        >
                                            {sendingMessage &&
                                                <HStack
                                                    width='100%'
                                                    justifyContent='start'
                                                >
                                                    <Skeleton
                                                        px={{ base: 4, md: 4 }}
                                                        py={{ base: 4, md: 3 }}
                                                        borderRadius='16px'
                                                        borderBottomStartRadius='0px'
                                                        boxShadow='lg'
                                                        height='42px'
                                                        width='90%'
                                                        speed={0.4}
                                                    />
                                                </HStack>
                                            }
                                            {messages && messages.length == 0 &&
                                                <HStack
                                                    width='100%'
                                                    justifyContent='center'
                                                    spacing={3}
                                                    py={4}
                                                >
                                                    <p className='text-sm md:text-base font-semibold text-gray-800'>
                                                        No chats yet.
                                                    </p>
                                                </HStack>
                                            }
                                            {!messages &&
                                                <HStack
                                                    width='100%'
                                                    justifyContent='center'
                                                    spacing={3}
                                                    py={4}
                                                >
                                                    <Spinner
                                                        speed='0.2s'
                                                    />
                                                    <p className='text-sm md:text-base font-semibold text-gray-800'>
                                                        Loading Conversation...
                                                    </p>
                                                </HStack>
                                            }
                                            {messages && messages.map((chat) => {
                                                if (chat.role == 'user') {
                                                    return (
                                                        <HStack
                                                            width='100%'
                                                            justifyContent='end'
                                                        >
                                                            <VStack
                                                                alignItems='start'
                                                                style={{
                                                                    background: 'linear-gradient(135deg, #89c8ff, #4d9de4)' // Adjusted colors for a slightly darker blue
                                                                }}
                                                                px={{ base: 4, md: 4 }}
                                                                py={{ base: 4, md: 3 }}
                                                                borderRadius='16px'
                                                                borderBottomEndRadius='0px'
                                                                boxShadow='lg'
                                                                maxWidth='100%'
                                                            >
                                                                <p className='text-sm md:text-base font-semibold text-white'>
                                                                    {chat.text_json}
                                                                </p>
                                                            </VStack>
                                                        </HStack>
                                                    );
                                                } else {
                                                    return (
                                                        <HStack
                                                            width='100%'
                                                            maxWidth='100%'
                                                            justifyContent='start'
                                                        >
                                                            <VStack
                                                                alignItems='start'
                                                                backgroundColor='#f0f0f0'
                                                                px={{ base: 4, md: 4 }}
                                                                py={{ base: 4, md: 3 }}
                                                                borderRadius='16px'
                                                                borderBottomStartRadius='0px'
                                                                boxShadow='lg'
                                                                maxWidth='100%'
                                                                spacing={1}
                                                            >
                                                                {chat.text_json && JSON.parse(chat.text_json).map((sentence, index) => {
                                                                    return (
                                                                        <p className='text-sm md:text-base font-semibold text-gray-900 inline break-words'>
                                                                            {sentence.answer}
                                                                            <span>
                                                                                {sentence['source_url'] && sentence['source_url'].length > 0 && isValidUrl(sentence['source_url']) &&
                                                                                    <a
                                                                                        href={sentence['source_url']}
                                                                                        target='_blank'
                                                                                        style={{ display: 'inline' }}
                                                                                        className='ml-1 text-indigo-600 hover:text-indigo-500 hover:underline'
                                                                                    >
                                                                                        [{index + 1}]
                                                                                    </a>
                                                                                }
                                                                                {sentence['source_url'] && sentence['source_url'].length > 0 && !isValidUrl(sentence['source_url']) &&
                                                                                    <DocumentTextIcon className="ml-1 h-4 w-4 text-indigo-600 inline" aria-hidden="true" />
                                                                                }
                                                                                {sentence['source_url'] && sentence['source_url'].length > 0 && !isValidUrl(sentence['source_url']) &&
                                                                                    <p className='text-indigo-600 ml-1 break-all inline'>
                                                                                        {sentence['source_url']}
                                                                                    </p>
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    );
                                                                })}
                                                            </VStack>
                                                        </HStack>
                                                    );
                                                }
                                            })}
                                        </VStack>
                                        {error &&
                                            <p className='text-red-500'>
                                                {error}
                                            </p>
                                        }
                                        {sendingMessage &&
                                            <div className='w-full bg-amber-300 rounded-lg text-white p-4'>
                                                <HStack
                                                    spacing={4}
                                                >
                                                    <Spinner
                                                        size='sm'
                                                        minWidth='min-content'
                                                        speed='0.2s'
                                                    />
                                                    <p>
                                                        We're getting a lot of traffic and scaling our systems. We appreciate your patience.
                                                    </p>
                                                </HStack>
                                            </div>
                                        }
                                        {suggestedQuestions && suggestedQuestions.hasOwnProperty(id) && !sendingMessage && messages && messages.length < 8 &&
                                            <VStack
                                                alignItems='start'
                                                width='100%'
                                            >
                                                <p className='text-sm'>
                                                    Suggested Questions
                                                </p>
                                                <Wrap width='100%'>
                                                    {suggestedQuestions[id].map((question) => {
                                                        return (
                                                            <button
                                                                type="button"
                                                                className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                onClick={() => sendSelectedMessage(question)}
                                                            >
                                                                {question}
                                                            </button>
                                                        );
                                                    })}
                                                </Wrap>
                                            </VStack>
                                        }
                                        <InputGroup
                                            size={{ base: 'md', md: 'lg' }}
                                        >
                                            <Input
                                                type='text'
                                                value={currMessage}
                                                onChange={(e) => setCurrMessage(e.target.value)}
                                                placeholder='What is the meaning of life?'
                                                width='100%'
                                                focusBorderColor='#3F51B5'
                                                borderColor='#3F51B5'
                                                onKeyDown={(e) => handleKeyDown(e)}
                                                disabled={sendingMessage}
                                                ref={inputRef}
                                                resize='none'
                                                pr='4.5rem'
                                            />
                                            <InputRightElement
                                                width='4.5rem'
                                            >
                                                <Button
                                                    onClick={() => sendingMessage ? null : sendMessage()}
                                                    disabled={sendingMessage}
                                                    colorScheme='blue'
                                                    height={{ base: '32px', md: '42px' }}
                                                    width={{ base: '32px', md: '42px' }}
                                                >
                                                    <Icon as={BiSolidSend} boxSize='24px' />
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </VStack>
                                </Container>
                            </VStack>
                        </div>
                    </div>

                </div >
            </main >

        </div >
    )
}
