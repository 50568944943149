/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "mentorchat",
            "endpoint": "https://kunj138zr9.execute-api.us-east-1.amazonaws.com/lalaland",
            "region": "us-east-1"
        },
        {
            "name": "mentorstripepublic",
            "endpoint": "https://bca80x3zud.execute-api.us-east-1.amazonaws.com/lalaland",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rpb4b5rbuzdjpbykh6z5vpevly.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vbz3wjxvvzaifaad2ug7bikyqm",
    "aws_cognito_identity_pool_id": "us-east-1:faf2fc0d-567c-4ae7-b6f7-dc2317bdd795",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_z2mLNJdDK",
    "aws_user_pools_web_client_id": "4hovqvje589pnfbjau97p3bed4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mcstorage01049-lalaland",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
