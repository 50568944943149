/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useEffect, useState } from 'react'
import { Dialog, RadioGroup } from '@headlessui/react'
import { ArrowLeftIcon, ArrowRightIcon, Bars3Icon, BookOpenIcon, ChatBubbleLeftIcon, EnvelopeIcon, ForwardIcon, PhoneIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChatBubbleLeftRightIcon, CheckIcon } from '@heroicons/react/20/solid'
import { Icon, HStack, Spinner, Skeleton } from '@chakra-ui/react'
import { API } from 'aws-amplify'
import { GiPlainCircle } from 'react-icons/gi'

const pricing = {
    frequencies: [
        { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
        { value: 'annually', label: 'Annually', priceSuffix: '/year' },
    ],
    tiers: [
        {
            name: 'Success in Life',
            priceId: 'price_1O3T3ODI0BJFO6gFMUJqR38Y',
            id: 'tier-solo',
            href: '#',
            price: { monthly: '$7', chats: 150 },
            description: 'Everyday advice to succeed in life and business.',
            features: [
                "Chat with history's greatest.",
                "Private weekly subscriber's newsletter & content.",
            ],
            mostPopular: false,
        },
        {
            name: 'Success in Business',
            priceId: 'price_1O3T8cDI0BJFO6gFtH5g85up',
            id: 'tier-startup',
            href: '#',
            price: { monthly: '$14', chats: 500 },
            description: 'Ask mentors specific questions about your business.',
            features: [
                "Chat with history's greatest.",
                "Private weekly subscriber's newsletter & content.",
            ],
            extraFeatures: [
                {
                    icon: EnvelopeIcon,
                    text: "Priority Support",
                },
                {
                    icon: UsersIcon,
                    text: "Access to Beta mentors before everyone else.",
                },
            ],
            specialFeatures: [
                {
                    icon: ChatBubbleLeftIcon,
                    text: "Private group chat of subscribers.",
                },
            ],
            mostPopular: true,
        },
        {
            name: 'Learn from History',
            priceId: 'price_1O3TA1DI0BJFO6gF2L197cjR',
            id: 'tier-enterprise',
            href: '#',
            price: { monthly: '$49', chats: 2000 },
            description: 'Ask every mentor a question at once.',
            features: [
                "Chat with history's greatest.",
                "Private weekly subscriber's newsletter & content.",
            ],
            extraFeatures: [
                {
                    icon: EnvelopeIcon,
                    text: "Priority Support",
                },
                {
                    icon: UsersIcon,
                    text: "Access to Beta mentors before everyone else.",
                },
                {
                    icon: ForwardIcon,
                    text: "Chat with all mentors at once. (Coming Soon)",
                }
            ],
            specialFeatures: [
                {
                    icon: ChatBubbleLeftIcon,
                    text: "Private group chat of subscribers.",
                },
                {
                    icon: PhoneIcon,
                    text: "Meet Brad, the creator of Lalaland each month. Personally suggest new features and Personas.",
                },
            ],
            mostPopular: false,
        },
    ],
}
const faqs = [
    {
        id: 1,
        question: "What's the group chat?",
        answer:
            "We have a small community for subscribers where they can chat and discuss ideas with each other. We often have calls and events with mentors and subscribers.",
    },
];

const footerNavigation = {
    solutions: [
        { name: 'Marketing', href: '#' },
        { name: 'Analytics', href: '#' },
        { name: 'Commerce', href: '#' },
        { name: 'Insights', href: '#' },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Status', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Claim', href: '#' },
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ],
    social: [
        {
            name: 'Facebook',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'Instagram',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'Twitter',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
            ),
        },
        {
            name: 'GitHub',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'YouTube',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [frequency, setFrequency] = useState(pricing.frequencies[0])
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);


    useEffect(() => {
        getUser();
    }, []);

    async function getUser() {
        const out = await API.get('mentorchat', '/user', {
            response: true,
            queryStringParameters: {
                kind: 'get',
            },
        });
        console.log('user');
        console.log(out);
        setUser(out.data.user);
    }


    async function subscribe(priceId) {
        console.log('buy');
        setLoading(true);
        const out = await API.get('mentorchat', '/stripe-private', {
            response: true,
            queryStringParameters: {
                kind: 'checkout_subscription',
                price_id: priceId,
            },
        });
        console.log(out);
        try {
            window.location.href = out.data.checkout_url;
        } catch (error) {
            console.error('Error getting checkout');
        }
        setLoading(false);
    }

    var tierNumber = -1;
    if (user && user.tier && user.tier != null) {
        tierNumber = 3 - Number(user.tier);
    }

    return (
        <div className="bg-gray-900">
            <main>
                {/* Pricing section */}
                <div className="mx-auto pt-16 max-w-7xl px-6 sm:pt-32 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center">
                        <h1 className="text-base font-semibold leading-7 text-indigo-400">Pricing</h1>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                            All the Greats on Demand
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                        Have 1-on-1 conversations with history's greatest minds on demand. Get advice, ask questions, and learn from the best. More added every week.
                    </p>
                    {user && user.tier != null &&
                        <div className="pointer-events-auto mt-6 flex items-center justify-between gap-x-6 bg-indigo-700 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                            <span className='text-gray-300'>
                                <p>
                                    Thank you for supporting Lalaland. This is a fun project that I hope delivers as much value to others as it does for me.
                                </p>
                                <p>
                                    If you are a Tier 2 or Tier 3 subscriber, please email me at <a href="mailto:mike@webtranspose.com" className="font-semibold text-indigo-400 hover:text-indigo-300">mike@webtranspose.com</a> and I'll send you a link to join the community group chats.
                                </p>
                            </span>
                        </div>
                    }
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {pricing.tiers.map((tier, index) => (
                            <div
                                key={tier.id}
                                className={classNames(
                                    tier.mostPopular ? 'bg-white/5 ring-2 ring-indigo-500' : 'ring-1 ring-white/10',
                                    'rounded-3xl p-8 xl:p-10'
                                )}
                            >
                                <div className="flex items-center justify-between gap-x-4">
                                    <h2 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                                        <p>
                                            {tier.name}
                                        </p>
                                    </h2>
                                    {tier.mostPopular ? (
                                        <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                                            Popular
                                        </p>
                                    ) : null}
                                </div>
                                <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                                <p className="mt-6 flex items-baseline gap-x-1">
                                    <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                                    <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                                </p>
                                {!user &&
                                    <Skeleton
                                        mt={4}
                                        width='100%'
                                        height={12}
                                        borderRadius='8px'
                                    />
                                }
                                {user && (tierNumber) < (index) &&
                                    <a
                                        href={tier.href}
                                        aria-describedby={tier.id}
                                        className={classNames(
                                            tier.mostPopular
                                                ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                                                : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                                            'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                        )}
                                        onClick={() => subscribe(tier.priceId)}
                                    >
                                        <HStack
                                            width='100%'
                                            justifyContent='center'
                                        >
                                            {loading &&
                                                <Spinner size='sm' />
                                            }
                                            {user && user.tier == null &&
                                                <p>
                                                    Join Lalaland
                                                </p>
                                            }
                                            {user && user.tier != null &&
                                                <p>
                                                    Upgrade
                                                </p>
                                            }
                                            <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
                                        </HStack>
                                    </a>
                                }
                                {user && (tierNumber) == (index) &&
                                    <a
                                        href={tier.href}
                                        aria-describedby={tier.id}
                                        className={classNames(
                                            tier.mostPopular
                                                ? 'bg-indigo-500 text-white shadow-sm focus-visible:outline-indigo-500 cursor-default'
                                                : 'bg-white/10 text-white focus-visible:outline-white cursor-default',
                                            'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-default'
                                        )}
                                    >
                                        <HStack
                                            width='100%'
                                            justifyContent='center'
                                        >
                                            <Icon as={GiPlainCircle} className="h-4 w-4" aria-hidden="true" />
                                            <p>
                                                Subscribed
                                            </p>
                                        </HStack>
                                    </a>
                                }
                                {user && (tierNumber) > (index) &&
                                    <a
                                        href={tier.href}
                                        aria-describedby={tier.id}
                                        className={classNames(
                                            tier.mostPopular
                                                ? 'bg-indigo-500 text-white shadow-sm focus-visible:outline-indigo-500 cursor-default'
                                                : 'bg-white/10 text-white focus-visible:outline-white cursor-default',
                                            'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-default'
                                        )}
                                    >
                                        <HStack
                                            width='100%'
                                            justifyContent='center'
                                        >
                                            <p>
                                                Joined Lalaland
                                            </p>
                                        </HStack>
                                    </a>
                                }
                                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                    <li className="flex gap-x-3">
                                        <ChatBubbleLeftRightIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                        {tier.price.chats} Chats / month
                                    </li>
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                            {feature}
                                        </li>
                                    ))}
                                    {tier.extraFeatures?.map((feature) => (
                                        <li key={feature.text} className="flex gap-x-3">
                                            <HStack
                                                alignItems='start'
                                            >
                                                <Icon as={feature.icon} className="h-6 w-5 flex-none text-white" aria-hidden="true" boxSize={5} mt={1} />
                                                <p>
                                                    {feature.text}
                                                </p>
                                            </HStack>
                                        </li>
                                    ))}
                                    {tier.specialFeatures?.map((feature) => (
                                        <li key={feature.text} className="flex gap-x-3 animate-pulse w-full">
                                            <HStack
                                                backgroundColor='#4f46e5'
                                                p={3}
                                                borderRadius='16px'
                                                width='100%'
                                                alignItems='start'
                                            >
                                                <Icon as={feature.icon} className="h-6 w-5 flex-none text-white" aria-hidden="true" boxSize={5} mt={1} />
                                                <p>
                                                    {feature.text}
                                                </p>
                                            </HStack>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                {/* FAQ section */}
                <div className="mx-auto mt-24 pb-24 max-w-7xl px-6 sm:mt-56 lg:px-8">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
                    <p className="mt-6 max-w-2xl text-base leading-7 text-gray-300">
                        Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
                        <a href="mailto:mike@webtranspose.com" className="font-semibold text-indigo-400 hover:text-indigo-300">
                            send me an email
                        </a>{' '}
                        and I’ll get back to you {':)'}
                    </p>
                    <div className="mt-20">
                        <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
                            {faqs.map((faq) => (
                                <div key={faq.id}>
                                    <dt className="text-base font-semibold leading-7 text-white">{faq.question}</dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-300">{faq.answer}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </main>
        </div>
    )
}
