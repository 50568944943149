import {
    ArrowPathIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/react/20/solid'
import { ArrowRightIcon, BoltIcon, CalendarDaysIcon, ExclamationCircleIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Spinner, Icon, Box, HStack, Image, Stack, VStack, Input, Container, Highlight, IconButton, Divider } from '@chakra-ui/react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { TypeAnimation } from 'react-type-animation';
import { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Marquee from 'react-fast-marquee';
import { API } from 'aws-amplify';



export default function SignUpBox({ ...props }) {

    const [email, setEmail] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    function isLegitEmail(email) {
        const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return pattern.test(email);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            submitEmail();
        }
    };

    async function submitEmail() {
        setLoadingEmail(true);
        setValidEmail(true);

        if (isLegitEmail(email)) {
            const out = await API.get('mentorchat', '/email', {
                response: true,
                queryStringParameters: {
                    kind: 'add',
                    email: email,
                },
            });
            if (out.status == 200) {
                setSubmitSuccess(true);
            }
        } else {
            setValidEmail(false);
            setLoadingEmail(false);
        }
        setLoadingEmail(false);
    }


    return (
        <VStack
            alignItems='start'
            width='100%'
            backgroundColor='white'
            borderRadius='6px'
            p='4'
            my={4}
            spacing={0}
            {...props}
        >
            <p className='font-semibold text-blue-700'>
                Timeless Weekly Email
            </p>
            <h2 className='text-2xl mt-1 font-semibold'>
                <Highlight
                    query={['greatest mentors', 'every week', 'life & business']}
                    styles={{ backgroundColor: 'yellow.100', p: 1, rounded: 'sm' }}
                >
                    ✉️ Advice on life & business from history's greatest mentors delivered to your inbox every week.
                </Highlight>
            </h2>
            <VStack
                alignItems='start'
                spacing={0}
                mt={5}
                width='100%'
            >
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                </label>
                <HStack
                    width='100%'
                    alignItems='center'
                    mt={2}
                >
                    <div className="w-full relative rounded-md shadow-sm">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className={validEmail ? "block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6" : "block w-full rounded-md border-0 py-3 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-base sm:leading-6"}
                            placeholder="you@example.com"
                            aria-invalid="true"
                            src={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        {!validEmail &&
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                        }
                    </div>
                    {!submitSuccess &&
                        <button
                            type="button"
                            className="min-w-fit animate-pulse rounded-md bg-blue-600 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            onClick={() => submitEmail()}
                        >
                            <HStack>
                                {loadingEmail &&
                                    <Spinner size='sm' />
                                }
                                <p>
                                    Sign Up
                                </p>
                                <Icon as={FiArrowRight} />
                            </HStack>
                        </button>
                    }
                    {submitSuccess &&
                        <button
                            type="button"
                            className="min-w-fit rounded-md bg-green-600 px-3 py-3 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-default"
                        >
                            <HStack>
                                <p>
                                    🎉
                                </p>
                                <p>
                                    Success
                                </p>
                            </HStack>
                        </button>
                    }
                </HStack>

                {!validEmail &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        Not a valid email address.
                    </p>
                }

            </VStack>
        </VStack>
    );
}
