import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    Bars3Icon,
    CalendarIcon,
    ChartPieIcon,
    ChatBubbleBottomCenterIcon,
    ChatBubbleBottomCenterTextIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react'
import { API } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import { Icon, Tooltip, HStack, Skeleton, Image, VStack, Text } from '@chakra-ui/react'
import { GiPlainCircle } from 'react-icons/gi';

const navigation = [
    // { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function PrivateNavbar({ children }) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [recentChats, setRecentChats] = useState(null);
    const [user, setUser] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        getRecentMentorIds();
        getUser();
    }, []);

    async function getUser() {
        const out = await API.get('mentorchat', '/user', {
            response: true,
            queryStringParameters: {
                kind: 'get',
            },
        });
        console.log('user');
        console.log(out);
        setUser(out.data.user);
    }

    async function getRecentMentorIds() {
        const out = await API.get('mentorchat', '/mentors', {
            response: true,
            queryStringParameters: {
                kind: 'get_recent_mentor_ids',
            },
        });
        setRecentChats(out.data.mentor_ids);
    }

    function idToName(id) {
        return id.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }


    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <a href='/dashboard'>
                                                <img
                                                    className="h-8 mt-8 w-auto"
                                                    src="/lalaland.png"
                                                    alt="Lalaland"
                                                />
                                            </a>
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                                <li>
                                                    <ul role="list" className="-mx-2 space-y-1">
                                                        {navigation.map((item) => (
                                                            <li key={item.name}>
                                                                <a
                                                                    href={item.href}
                                                                    className={classNames(
                                                                        item.current
                                                                            ? 'bg-gray-800 text-white'
                                                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                                <li>
                                                    <div className="text-xs font-semibold leading-6 text-gray-400">
                                                        Remaining Chats
                                                    </div>
                                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                        {user &&
                                                            <Tooltip label='Upgrade to Unlock More Chats' classNmae='w-full min-w-full'>
                                                                <a href='/credits'>
                                                                    <button
                                                                        type="button"
                                                                        className="flex-grow w-full ml-2 mr-2 rounded-sm bg-stone-100 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-stone-300 hover:bg-slate-200"
                                                                    >
                                                                        <HStack
                                                                            width='100%'
                                                                            justifyContent='space-between'
                                                                        >
                                                                            <HStack>
                                                                                <ChatBubbleBottomCenterTextIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                                                                <p>
                                                                                    {user.credits}
                                                                                </p>
                                                                            </HStack>
                                                                            {user.tier &&
                                                                                <HStack>
                                                                                    <Icon as={GiPlainCircle} boxSize={4} />
                                                                                    <p>
                                                                                        Joined Lalaland
                                                                                    </p>
                                                                                </HStack>
                                                                            }
                                                                            {!user.tier &&
                                                                                <HStack spacing={1}>
                                                                                    <Icon as={GiPlainCircle} boxSize={4} />
                                                                                    <p>
                                                                                        Join Lalaland
                                                                                    </p>
                                                                                    <ArrowRightIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                                                                                </HStack>
                                                                            }
                                                                        </HStack>
                                                                    </button>
                                                                </a>
                                                            </Tooltip>
                                                        }
                                                    </ul>
                                                </li>
                                                <li>
                                                    <div className="text-xs font-semibold leading-6 text-gray-400">
                                                        Recent Chats
                                                    </div>
                                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                        {!recentChats &&
                                                            <Skeleton
                                                                height="2rem"
                                                                width="100%"
                                                            />
                                                        }
                                                        {recentChats && recentChats.map((mentor) => (
                                                            <li key={idToName(mentor)}>
                                                                <a
                                                                    href={`/chat/${mentor}`}
                                                                    className={classNames(
                                                                        (mentor == id)
                                                                            ? 'bg-gray-800 text-white'
                                                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                    )}
                                                                >
                                                                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                                        <img
                                                                            src={`/img/${mentor}.png`}
                                                                        />
                                                                    </span>
                                                                    <span className="truncate">{idToName(mentor)}</span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
                        <div className="flex h-42 shrink-0 items-center">
                            <VStack
                                alignItems='start'
                            >
                                <a href='/dashboard'>
                                    <img
                                        className="h-8 mt-8 w-auto"
                                        src="/lalaland.png"
                                        alt="Lalaland"
                                    />
                                </a>
                            </VStack>
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        {navigation.map((item) => (
                                            <li key={item.name}>
                                                <a
                                                    href={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-gray-800 text-white'
                                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                    )}
                                                >
                                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <div className="text-xs font-semibold leading-6 text-gray-400">
                                        Remaining Chats
                                    </div>
                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                        {user &&
                                            <Tooltip label='Upgrade to Unlock More Chats' classNmae='w-full min-w-full'>
                                                <a href='/credits'>
                                                    <button
                                                        type="button"
                                                        className="flex-grow w-full ml-2 mr-2 rounded-sm bg-stone-100 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-stone-300 hover:bg-slate-200"
                                                    >
                                                        <HStack
                                                            width='100%'
                                                            justifyContent='space-between'
                                                        >
                                                            <HStack>
                                                                <ChatBubbleBottomCenterTextIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                                                <p>
                                                                    {user.credits}
                                                                </p>
                                                            </HStack>
                                                            {user.tier &&
                                                                <HStack>
                                                                    <Icon as={GiPlainCircle} boxSize={4} />
                                                                    <p>
                                                                        Joined Lalaland
                                                                    </p>
                                                                </HStack>
                                                            }
                                                            {!user.tier &&
                                                                <HStack spacing={1}>
                                                                    <Icon as={GiPlainCircle} boxSize={4} />
                                                                    <p>
                                                                        Join Lalaland
                                                                    </p>
                                                                    <ArrowRightIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                                                                </HStack>
                                                            }
                                                        </HStack>
                                                    </button>
                                                </a>
                                            </Tooltip>
                                        }
                                    </ul>
                                </li>
                                <li>
                                    <div className="text-xs font-semibold leading-6 text-gray-400">
                                        Recent Chats
                                    </div>
                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                        {!recentChats &&
                                            <Skeleton
                                                height="2rem"
                                                width="100%"
                                            />
                                        }
                                        {recentChats && recentChats.map((mentor) => (
                                            <li key={idToName(mentor)}>
                                                <a
                                                    href={`/chat/${mentor}`}
                                                    className={classNames(
                                                        (mentor == id)
                                                            ? 'bg-gray-800 text-white'
                                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                    )}
                                                >
                                                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                        <img
                                                            src={`/img/${mentor}.png`}
                                                            className='rounded-lg'
                                                        />
                                                    </span>
                                                    <span className="truncate">{idToName(mentor)}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <a href='/dashboard'>
                        <img
                            className="h-8 w-auto"
                            src="/lalaland.png"
                            alt="Lalaland"
                        />
                    </a>
                    <a href="/dashboard">
                        {/*
                        <span className="sr-only">Your profile</span>
                        <img
                            className="h-8 w-8 rounded-full bg-gray-800"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                        />
                                                */}
                    </a>
                </div>

                <main className="lg:pl-72">
                    {children}
                </main>
            </div>
        </>
    )
}

function Header() {
    return (
        <VStack
            mb='10'
            alignItems='center'
            width='100%'
            justifyContent='center'
            cursor='pointer'
            onClick={() => window.location.href = '/'}
            spacing={5}
        >
            <Image
                alt="logo"
                height='50px'
                src='/lalaland.png'
            />
        </VStack>
    );
}

export default withAuthenticator(PrivateNavbar, {
    components: {
        Header,
        // SignIn: {
        // Header: SignInHeader,
        // Footer: SignInFooter
        // },
        // Footer
    }
});