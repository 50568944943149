import {
    ArrowPathIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/react/20/solid'
import { ArrowRightIcon, BoltIcon, CalendarDaysIcon, ExclamationCircleIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Spinner, Icon, Box, HStack, Image, Stack, VStack, Input, Container, Highlight, IconButton, Divider, MenuDivider } from '@chakra-ui/react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { TypeAnimation } from 'react-type-animation';
import { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Marquee from 'react-fast-marquee';
import { API } from 'aws-amplify';
import SignUpBox from '../components/SignUpBox';
import { BsFillCircleFill } from 'react-icons/bs';
import { FaTwitter } from 'react-icons/fa';


const primaryFeatures = [
    {
        name: 'Server monitoring',
        description:
            'Non quo aperiam repellendus quas est est. Eos aut dolore aut ut sit nesciunt. Ex tempora quia. Sit nobis consequatur dolores incidunt.',
        href: '#',
        icon: BoltIcon,
    },
    {
        name: 'Collaborate',
        description:
            'Vero eum voluptatem aliquid nostrum voluptatem. Vitae esse natus. Earum nihil deserunt eos quasi cupiditate. A inventore et molestiae natus.',
        href: '#',
        icon: UsersIcon,
    },
    {
        name: 'Task scheduling',
        description:
            'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
        href: '#',
        icon: CalendarDaysIcon,
    },
]
const secondaryFeatures = [
    {
        name: 'Push to deploy.',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'SSL certificates.',
        description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
        icon: LockClosedIcon,
    },
    {
        name: 'Simple queues.',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Advanced security.',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
        icon: FingerPrintIcon,
    },
    {
        name: 'Powerful API.',
        description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
        icon: Cog6ToothIcon,
    },
    {
        name: 'Database backups.',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ',
        icon: ServerIcon,
    },
]
const stats = [
    { id: 1, name: 'Developers on the platform', value: '8,000+' },
    { id: 2, name: 'Daily requests', value: '900m+' },
    { id: 3, name: 'Uptime guarantee', value: '99.9%' },
    { id: 4, name: 'Projects deployed', value: '12m' },
]
const footerNavigation = {
    social: [
        {
            name: 'Twitter',
            href: 'https://twitter.com/mikegeecmu',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
            ),
        },
        {
            name: 'YouTube',
            href: 'https://youtube.com/@mike-gee',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}


const homePersonas = {
    'john-f-kennedy': {
        'name': 'John F Kennedy',
        'title': '35th President of the United States',
    },
    'steve-jobs': {
        'name': 'Steve Jobs',
        'title': 'Co-Founder of Apple, Inc.',
    },
    'paul-graham': {
        'name': 'Paul Graham',
        'title': 'Co-Founder of ViaWeb, Y Combinator',
    },
    'andrew-carnegie': {
        'name': 'Andrew Carnegie',
        'title': 'Founder of Carnegie Steel Co.',
    },
};

const logoImgDict = [
    {
        'name': 'Apple',
        'img': '/logos/apple.png',
        'mentor_id': 'steve-jobs'
    },
    {
        'name': 'Carnegie Steel Co',
        'img': '/logos/carnegie.png',
        'mentor_id': 'andrew-carnegie'
    },
    {
        'name': 'Tesla',
        'img': '/logos/tesla.png',
        'mentor_id': 'elon-musk'
    },
    {
        'name': 'USA',
        'img': '/logos/usa.jpg',
        'mentor_id': 'benjamin-franklin'
    },
    {
        'name': 'Y Combinator',
        'img': '/logos/yc.png',
        'mentor_id': 'paul-graham'
    },
];

const logoImgs = [
    '/logos/apple.png',
    '/logos/carnegie.png',
    '/logos/tesla.png',
    '/logos/usa.jpg',
    '/logos/yc.png',
];

const navigation = [
    { name: 'Chat', href: '/' },
    { name: 'Weekly Advice', href: '/advice' },
    { name: 'Great Moments in History', href: '/feed' },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const todayFeed = [
    {
        'video_url': 'https://www.youtube.com/embed/iiC-E8vl7Fw?si=mheHz-uf10SUMaLh',
        'title': 'Kennedy announces plans to go to the moon',
        'quote': 'We choose to go to the Moon in this decade and do the other things, not because they are easy, but because they are hard.',
        'highlight': ['go to the Moon', 'easy', 'they are hard'],
        'about': 'At Rice University in Houston, Texas, on September 12, 1962, President John F. Kennedy speaks about the necessity for the U.S. to become an international leader in space exploration.',
        'id': 'john-f-kennedy',
    },
    {
        'image': 'https://www.pbs.org/wgbh/americanexperience/media/filer_public_thumbnails/filer_public/fd/2d/fd2d59bc-d34b-41e6-b53e-d5c095990c66/steel-workers-gaze-on-as-molten-steel-is-poured-from-ladle-to-casts-at-homestead-steel-works-december-31-1914-pd.jpg__2000x1616_q85_crop_subsampling-2_upscale.jpg',
        'title': 'Andrew Carnegie began work as a bobbin boy',
        'quote': 'It now became my duty to bathe the newly made spools in vats of oil. I never succeeded in overcoming the nausea produced by the smell of the oil. But if I had to lose breakfast, or dinner, I had all the better appetite for supper, and the allotted work was done.',
        'highlight': ['vats of oil', 'the nausea', 'lose breakfast', 'better appetite for supper', 'allotted work was done'],
        'about': 'At a young age, Andrew Carnegie started working on the factory floor as a bobbin boy for one dollar and twenty cents a week. His family nothing after immigrating to the United States. He enjoyed the feeling contributing to the well-being of his family as a young man of industry.',
        'id': 'andrew-carnegie',
    }
]


export default function GreatsFeed() {

    const [email, setEmail] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [countdown, setCountdown] = useState(null);

    const [selectedPersona, setSelectedPersona] = useState('steve-jobs');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);


    useEffect(() => {
        const interval = setInterval(() => {
            // countdown to 12pm tomorrow
            const now = new Date();
            const nextSunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()), 12, 0, 0);
            const diff = nextSunday - now;
            const days = Math.floor(diff / 1000 / 60 / 60 / 24);
            const hours = Math.floor(diff / 1000 / 60 / 60) - (days * 24);
            const minutes = Math.floor(diff / 1000 / 60) - (hours * 60) - (days * 24 * 60);
            const seconds = Math.floor(diff / 1000) - (minutes * 60) - (hours * 60 * 60) - (days * 24 * 60 * 60);
            setCountdown(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);
        }, 1000);
        return () => clearInterval(interval);
    }, []);



    function isLegitEmail(email) {
        const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return pattern.test(email);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            submitEmail();
        }
    };

    async function submitEmail() {
        setLoadingEmail(true);
        setValidEmail(true);

        if (isLegitEmail(email)) {
            const out = await API.get('mentorchat', '/email', {
                response: true,
                queryStringParameters: {
                    kind: 'add',
                    email: email,
                },
            });
            if (out.status == 200) {
                setSubmitSuccess(true);
            }
        } else {
            setValidEmail(false);
            setLoadingEmail(false);
        }
        setLoadingEmail(false);
    }


    return (
        <div className="bg-gray-900">
            {/* Header */}
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-start justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <VStack
                            alignItems='start'
                        >
                            <a href='/dashboard'>
                                <img
                                    className="h-8 w-auto"
                                    src="/lalaland.png"
                                    alt="Lalaland"
                                />
                            </a>
                        </VStack>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <HStack spacing={6}>
                            <a href="/dashboard" className="text-sm font-semibold leading-6 text-white">
                                Log in
                            </a>
                            <a href='/dashboard'>
                                <button
                                    type="button"
                                    className="animate-pulse rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                >
                                    <HStack>
                                        <p>
                                            Start Chatting
                                        </p>
                                        <Icon as={FiArrowRight} />
                                    </HStack>
                                </button>
                            </a>
                        </HStack>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <img
                                    className="h-8 w-auto"
                                    src='/lalaland.png'
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <VStack spacing={6} alignItems='start'>
                                        <a
                                            href="/dashboard"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            Log in
                                        </a>
                                        <button
                                            type="button"
                                            className="animate-pulse rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                            onClick={() => window.open('/dashboard')}
                                        >
                                            <HStack>
                                                <p>
                                                    Start Chatting
                                                </p>
                                                <Icon as={FiArrowRight} />
                                            </HStack>
                                        </button>
                                    </VStack>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

            <main>
                <div className="pt-10 relative isolate overflow-hidden min-h-screen">
                    <svg
                        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
                    </svg>
                    <div
                        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-20">
                        <Container
                            maxW='container.md'>
                            <VStack
                                alignItems='start'
                            >
                                <img
                                    className='hidden md:flex h-20 w-20 rounded-full border-4 border-white object-cover'
                                    src={'/circle/steve-jobs-lisa.jpg'}
                                />
                                <button
                                    type="button"
                                    className="mt-10 sm:mt-16 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <HStack
                                        spacing={4}
                                    >
                                        <Icon as={BsFillCircleFill} boxSize={3} color='green.300' />
                                        <VStack
                                            spacing={1}
                                            alignItems='start'
                                        >
                                            <p>
                                                New Moments in
                                            </p>
                                            <p>
                                                {countdown}
                                            </p>
                                        </VStack>
                                    </HStack>
                                </button>
                                <HStack
                                    width='100%'
                                >
                                    <h1 className="mt-4 md:mt-4 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                        Great Moments in History
                                    </h1>
                                </HStack>
                                <VStack
                                    alignItems='start'
                                    spacing={0}
                                    mt={4}
                                >
                                    <p className="hidden sm:flex  text-lg leading-8 text-gray-300">
                                        History can be defined by few pivotal moments.
                                        May we be inspired and learn from them.
                                    </p>
                                </VStack>

                                <Divider py={5} />
                            </VStack>
                            <VStack
                                mt={10}
                                alignItems='start'
                                width='100%'
                                spacing={'80px'}
                            >
                                {todayFeed.map((news, index) => {
                                    return (
                                        <VStack
                                            alignItems='start'
                                            width='100%'
                                            spacing={10}
                                        >
                                            {news.quote &&
                                                <figure className="relative isolate pt-6 sm:pt-12">
                                                    <svg
                                                        viewBox="0 0 162 128"
                                                        fill="none"
                                                        aria-hidden="true"
                                                        className="absolute left-0 top-0 -z-10 h-32 stroke-white/20"
                                                    >
                                                        <path
                                                            id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                                                            d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                                                        />
                                                        <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
                                                    </svg>
                                                    <blockquote className="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
                                                        <p>
                                                            <Highlight
                                                                query={news.highlight ?? ''}
                                                                // styles={{ px: '2', py: '1', rounded: 'lg', bg: 'red.100' }}
                                                                styles={{ backgroundColor: 'yellow.100', p: 1, rounded: 'sm' }}
                                                            >
                                                                {news.quote}
                                                            </Highlight>
                                                        </p>
                                                    </blockquote>
                                                </figure>
                                            }
                                            <VStack
                                                alignItems='start'
                                                width='100%'
                                                backgroundColor='white'
                                                borderRadius='md'
                                                p={4}
                                                boxShadow='md'
                                                spacing={4}
                                            >
                                                {news.video_url &&
                                                    <iframe height='350px' width="100%" src={news.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                }
                                                {news.image &&
                                                    <Image
                                                        src={news.image}
                                                        height="350px"
                                                        maxHeight="350px"
                                                        width="100%"
                                                        objectFit="contain"
                                                    />
                                                }
                                                <VStack
                                                    alignItems='start'
                                                    width='100%'
                                                    spacing={1}
                                                >
                                                    <h2 className='text-3xl font-bold'>
                                                        {news.title}
                                                    </h2>
                                                    <p className='text-md'>
                                                        {news.about}
                                                    </p>
                                                </VStack>
                                                <Divider />
                                                <HStack
                                                    width='100%'
                                                    justifyContent='space-between'
                                                >
                                                    <HStack>
                                                        <img
                                                            className='h-10 w-10 rounded-full border-4 border-white object-cover'
                                                            src={`/img/${news.id}.png`}
                                                        />
                                                        <p>
                                                            {homePersonas[news.id].name}
                                                        </p>
                                                    </HStack>
                                                    <HStack spacing={4}>
                                                        <a class="twitter-share-button"
                                                            href={`https://twitter.com/intent/tweet?text=${news.title.replace(' ', '%20')}%0A${news.about}%20-%20via%20timeless.chat&url=${(news.video_url != null) ? news.video_url : news.image}`}
                                                        >
                                                            <Icon as={FaTwitter} boxSize={6} _hover={{ color: 'gray.300' }} />
                                                        </a>
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-bold text-white shadow-sm ring-1 ring-inset ring-blue-300 hover:bg-blue-500"
                                                        >
                                                            <HStack
                                                                spacing={2}
                                                            >
                                                                <img
                                                                    src={`/img/${news.id}.png`}
                                                                    className='h-5 w-5 rounded-full border-4 border-white object-cover'
                                                                />
                                                                <p>
                                                                    Chat
                                                                </p>
                                                                <Icon as={FiArrowRight} boxSize={4} />
                                                            </HStack>
                                                        </button>
                                                    </HStack>
                                                </HStack>
                                            </VStack>
                                            {(index == 0) &&
                                                <SignUpBox />
                                            }
                                        </VStack>
                                    )
                                })}
                            </VStack>
                        </Container>
                    </div>
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                </div>

            </main >

            {/* Footer */}
            < footer aria-labelledby="footer-heading" className="relative" >
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-0 lg:px-8">
                    <div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                        <div className="flex space-x-6 md:order-2">
                            {footerNavigation.social.map((item) => (
                                <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400" target='_blank'>
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                </a>
                            ))}
                        </div>
                        <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                            &copy; 2023 Vetro Technologies, Inc. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer >
        </div >
    )
}
